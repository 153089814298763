<template>
    <div class="main">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
                :immediate-check="false"
            >
                <div v-for="(item, index) in list" :key="index" class="item">
                    <van-image class="avatar" round :src="item.avatar" fit="fill" />
                    <div class="rbody">
                        <div class="nameAndTime">
                            <div class="name">{{ item.nick_name }}</div>
                            <div class="time">{{ item.chat_time }}</div>
                        </div>

                        <img v-if="item.chat_type==1 && item.sex==1" class="callType"
                        round src="../../assets/videocallout.png" fit="fill" />
                        <img v-if="item.chat_type==1 && item.sex==2" class="callType"
                        round src="../../assets/video_call_in.png" fit="fill" />
                        <img v-if="item.chat_type==2 && item.sex==1" class="callType"
                        round src="../../assets/voice_call_out.png" fit="fill" />
                        <img v-if="item.chat_type==2 && item.sex==2" class="callType"
                        round src="../../assets/voicecallin.png" fit="fill" />

                        <div class="costAndDuration">
                            <div class="cost">消耗：{{ item.deduction_amount }} 金币</div>
                            <div class="duration">时长：{{ formatDuration(item.duration) }}</div>
                        </div>
                    </div>
                </div>
            </van-list>
        </van-pull-refresh>
    </div>
</template>

<script>
import { doRequest } from '@/utils/request'

export default {
    data() {
        return {
            page: 1,
            page_size: 20,

            refreshing: false,

            loading: false,
            finished: false,

            list: [],
        }
    },
    methods: {
        formatDuration(t) {
            let ret = ''
            ret = ret + (parseInt(t / 3600, 0) + '小时')
            t = t % 3600
            ret = ret + (parseInt(t / 60, 0) + '分')
            ret =ret + ((t % 60) + '秒')
            return ret
        },
        onRefresh() {
            this.page = 1
            this.list = []
            this.initData()
        },
        onLoad() {
            this.page++
            this.initData()
        },
        initData() {
            doRequest('/chat/record', {
                page: this.page,
                page_size: this.page_size,
            }, this.$route.query).then(res => {
                this.list = this.list.concat(res.list)
                this.loading = false
                this.refreshing = false
                if (res.list.length < this.page_size) {
                    this.finished = true
                }
            })
        },
    },
    mounted() {
        this.initData()
    },
}
</script>

<style scoped>
.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 375px;
}

.item {
    width: 375px;
    height: 99.7px;
    display: flex;
    align-items: center;
}

.item .avatar {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-left: 8px;
}

.item .rbody {
    width: 315px;
    height: 99.7px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dddddd;
}

.item .rbody .nameAndTime {
    display: flex;
    height: 45px;
    width: 120px;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 12px;
}

.item .rbody .nameAndTime .name {
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
}

.item .rbody .nameAndTime .time {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
}

.item .rbody .callType {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 2px;
}

.item .rbody .costAndDuration {
    display: flex;
    height: 45px;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 25px;
}

.item .rbody .costAndDuration .cost {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
}

.item .rbody .costAndDuration .duration {
    width: 120px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
}
</style>